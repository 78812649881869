export const palette = [
  //New optimized palette
  "#808080",	//0
  "#dadfe4",	//1
  "#e6e9ed",	//2
  "#fafafa",	//3
  "#f8bbd0",	//4
  "#ef5350",	//5
  "#e57373",	//6
  "#ef9a9a",	//7
  "#fff3e0",	//8
  "#ffa726",	//9
  "#ffb960",	//10
  "#ffcc80",	//11
  "#ffe0b2",	//12
  "#ffee58",	//13
  "#fff59d",	//14
  "#fff9c4",	//15
  "#dcedc8",	//16
  "#8bc34a",	//17
  "#aed581",	//18
  "#bfdf9f",	//19
  "#5ee2b0",	//20
  "#00ce3c",  //21
  "#00ba43",  //22
  "#119c3f",	//23
  "#57ecc1",	//24
  "#00e864",  //25
  "#00e05c",  //26
  "#00d545",  //27
  "#7afddd",	//28
  "#00e4c5",  //29
  "#00e0b2",  //30
  "#01eec6",  //31
  "#49efef",	//32
  "#00e7d8",  //33
  "#00e5d1",  //34
  "#01efde",  //35
  "#6addff",	//36
  "#00dafe",  //37
  "#01d6ff",  //38
  "#08acdc",  //39
  "#73cefe",	//40
  "#0d9bf7",  //41
  "#148de4",	//42
  "#2a77c9",	//43
  "#8693ff",	//44
  "#2196f3",	//45
  "#4668f6",	//46
  "#4153dc",	//47
  "#b095ff",	//48
  "#8453fd",	//49
  "#634acd",	//50
  "#5749c5",	//51
  "#ffb7ff",	//52
  "#e863fb",	//53
  "#d655ed",	//54
  "#d14fe9",	//55
  "#fc99e3",	//56
  "#e736c2",	//57
  "#e52fbe",	//58
  "#e334b6",	//59
  "#ed353e",	//60
  "#ffa726",	//61
  "#f4df0b",  //62
  "#66bb6a",	//63
  "#5cd100",  //64
  "#00d29e",  //65
  "#2388ff",	//66
  "#3669fd",	//67
  "#00b4d0",  //68
  "#475cdc",	//69
  "#f2f3f5",	//70
  "#eef0f2",	//71
  "#f72737",	//72
  "#d2ea7b",	//73
  "#c8df10",	//74
  "#7fe422",	//75
  "#00c931",  //76
  "#00d7a6",	//77
  "#00d8fc",  //78
  "#0b9bfc",  //79
  "#585cf5",	//80
  "#ac59f0",	//81
  "#d980dc",	//82
  "#b8814a",	//83
  "#ff9800",  //84
  "#abdf22",	//85
  "#9ee154",	//86
  "#66bb6a",	//87
  "#3bda47",	//88
  "#6fdeb9",	//89
  "#27dbda",	//90
  "#9cc8fd",	//91
  "#79b8f7",	//92
  "#afafef",	//93
  "#d580eb",	//94
  "#f74fca",	//95
  "#ea8a1f",	//96
  "#dbdb08",  //97
  "#9cd60d",  //98
  "#f3d335",	//99
  "#c8af41",	//100
  "#00ca69",  //101
  "#24d2b0",	//102
  "#757ebe",	//103
  "#5388db",	//104
  "#e5c5a6",	//105
  "#e93b3b",	//106
  "#f9a2a1",	//107
  "#ed9c65",	//108
  "#e1ca72",	//109
  "#b8da78",	//110
  "#98d52c",	//111
  "#626cbd",	//112
  "#cac8a0",	//113
  "#90d4c2",	//114
  "#ceddfe",	//115
  "#beccf7",	//116
  "#d2d7de",	//117
  "#dadfe4",	//118
  "#e6e9ed",	//119
  "#fe1624",	//120
  "#cd2724",	//121
  "#9ccc65",	//122
  "#009c1b",  //123
  "#ffff00",  //124
  "#beb212",	//125
  "#f5d01d",	//126
  "#e37829",	//127
];

export const rawPalette = [
  //LaunchpadXcolorPalette
  "#000000", //0
  "#3F3F3F", //1
  "#7F7F7F", //2
  "#FFFFFF", //3
  "#FF3F3F", //4
  "#FF0000", //5
  "#7F0000", //6
  "#3F0000", //7
  "#FFBF6F", //8
  "#FF3F00", //9
  "#7F1F00", //10
  "#3F0F00", //11
  "#FFAF2F", //12
  "#FFFF00", //13
  "#7F7F00", //14
  "#3F3F00", //15
  "#7FFF2F", //16
  "#4FFF00", //17
  "#2F7F00", //18
  "#173F00", //19
  "#4FFF3F", //20
  "#00FF00", //21
  "#007F00", //22
  "#003F00", //23
  "#4FFF4F", //24
  "#00FF1F", //25
  "#007F0F", //26
  "#003F07", //27
  "#4FFF5F", //28
  "#00FF5F", //29
  "#007F2F", //30
  "#003F17", //31
  "#4FFFBF", //32
  "#00FF9F", //33
  "#007F4F", //34
  "#003F27", //35
  "#4FBFFF", //36
  "#00AFFF", //37
  "#00577F", //38
  "#002F3F", //39
  "#4F7FFF", //40
  "#0057FF", //41
  "#002F7F", //42
  "#00173F", //43
  "#2F1FFF", //44
  "#0000FF", //45
  "#00007F", //46
  "#00003F", //47
  "#5F3FFF", //48
  "#2F00FF", //49
  "#17007F", //50
  "#0F003F", //51
  "#FF3FFF", //52
  "#FF00FF", //53
  "#7F007F", //54
  "#3F003F", //55
  "#FF3F6F", //56
  "#FF004F", //57
  "#7F002F", //58
  "#3F001F", //59
  "#FF0F00", //60
  "#9F3F00", //61
  "#7F4F00", //62
  "#2F2F00", //63
  "#003F00", //64
  "#003F1F", //65
  "#001F6F", //66
  "#0000FF", //67
  "#003F3F", //68
  "#1F00BF", //69
  "#5F3F4F", //70
  "#1F0F17", //71
  "#FF0000", //72
  "#BFFF2F", //73
  "#AFEF00", //74
  "#5FFF00", //75
  "#0F7F00", //76
  "#00FF5F", //77
  "#009FFF", //78
  "#002FFF", //79
  "#1F00FF", //80
  "#5F00EF", //81
  "#AF1F7F", //82
  "#2F0F00", //83
  "#FF2F00", //84
  "#7FDF00", //85
  "#6FFF1F", //86
  "#00FF00", //87
  "#3FFF2F", //88
  "#5FEF6F", //89
  "#3FFFCF", //90
  "#5F8FFF", //91
  "#2F4FCF", //92
  "#6F4FDF", //93
  "#DF1FFF", //94
  "#FF005F", //95
  "#FF4F00", //96
  "#BFAF00", //97
  "#8FFF00", //98
  "#7F5F00", //99
  "#3F2F00", //100
  "#00470F", //101
  "#0F4F1F", //102
  "#17172F", //103
  "#171F5F", //104
  "#5F3717", //105
  "#7F0000", //106
  "#DF3F2F", //107
  "#DF470F", //108
  "#FFBF1F", //109
  "#9FDF2F", //110
  "#6FAF0F", //111
  "#17172F", //112
  "#DFDF6F", //113
  "#7FEF8F", //114
  "#9F9FFF", //115
  "#8F6FFF", //116
  "#3F3F3F", //117
  "#6F6F6F", //118
  "#DFFFFF", //119
  "#9F0000", //120
  "#370000", //121
  "#17CF00", //122
  "#003F00", //123
  "#BFAF00", //124
  "#3F2F00", //125
  "#AF4F00", //126
  "#4F0F00"  //127
];

export default palette